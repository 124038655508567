<template>
  <div>
    <TitleWrapper :filter-option="false" title="NOTIFS" :display-breadcrumb="true" />
    <div
      :class="$style.minHieght80"
      class="main-content bg-primary-white rounded-t-lg mt-5 p-5 border-border-color-lighter border"
    >
      <!-- TITLE -->
      <div class="flex justify-between">
        <div v-i18n="notifications" class="font-roboto mt-3 text-text-color text-lg opacity-100">
          Settings List
        </div>
        <div>
          <UiSingleSelect
            v-model="selectedChannel"
            :hide-title="true"
            :options="channelsList"
            label="title"
            placeholder="Select Channel"
            class="min-w-1.5"
          />
        </div>
      </div>
      <div class="mt-1 rounded-lg border border-primary-grey"></div>
      <div v-if="!isLoading">
        <h1 class="text-3xl mt-3">Classes</h1>
        <div class="px-5">
          <AllSettingsComponent class="mt-8" />
          <AllSettingsComponent class="mt-8" />
          <AllSettingsComponent class="mt-8" />
        </div>
      </div>
      <div v-else class="mt-28">
        <Loader :content="true" />
      </div>
    </div>
    <div
      class="main-content flex bg-primary-white rounded-lg mt-5 px-10 py-12 border-border-color-lighter border"
    >
      <div>
        <h1 class="text-3xl font-bold">Unsubscribe from all Notifications</h1>
        <h2 class="text-xs text-text-primary-grey-light pt-2">
          Lorem ipsum da rorara Lorem ipsum, dolor sit amet consectetur
        </h2>
      </div>
      <div class="ml-auto">
        <ToggleButton
          v-model="toggleValue"
          class="mt-3"
          checkbox-color-class="bg-dark-orange"
          :is-status-hide="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import UiSingleSelect from '@components/UiElements/UiSingleSelect.vue'
import AllSettingsComponent from '@components/Settings/notifications/AllSettingToggleComponent.vue'
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'
/* Utils or mixins */
import GeneralUtil from '@src/mixins/general-mixins.js'
import { getTimeFromDateWithUTC } from '@utils/moment.util'
import { mapActions, mapState } from 'vuex'
import TIME_CONSTANTS from '@src/constants/date-time-constants'

export default {
  name: 'AllNotifictionsSettings',
  components: {
    TitleWrapper,
    Loader,
    UiSingleSelect,
    AllSettingsComponent,
    ToggleButton,
    // NoRecordFound: () => import('@components/BaseComponent/NoRecordFound.vue'),
  },

  mixins: [GeneralUtil],
  data() {
    return {
      notifications: 'notifications',
      isLoading: false,
      selectedChannel: '',
      channelsList: [
        { id: 1, title: 'Fee' },
        { id: 2, title: 'Chapters' },
        { id: 1, title: 'Lessons' },
      ],
      toggleValue: true,
    }
  },
  created() {},
  methods: {
    filterRecord() {},

    handleClick(eventName) {
      switch (eventName) {
        case 'show':
          this.showAddModal = true
          break
        case 'close':
          this.showAddModal = false
          break
        case 'cancel':
          this.showAddModal = false
          break
        default:
          this.showAddModal = !this.showAddModal
      }
    },
  },
}
</script>

<style lang="scss" module>
.minHieght80 {
  min-height: 80vh;
}
</style>
