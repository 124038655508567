<template>
  <div class="flex">
    <!-- TEXT -->
    <div>
      <h2 class="text-base font-bold">New Classes</h2>
      <h2 class="text-xs text-text-primary-grey-light pt-2">
        Lorem ipsum da rorara Lorem ipsum, dolor sit amet consectetur
      </h2>
    </div>
    <!-- TOGGLE BUTTON-->
    <div class="ml-auto">
      <ToggleButton v-model="toggleValue" class="mt-3" :is-status-hide="true" />
    </div>
  </div>
</template>

<script>
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'

export default {
  name: 'NotificationSettingsComponent',
  components: {
    ToggleButton,
  },
  data() {
    return {
      toggleValue: false,
    }
  },
}
</script>
